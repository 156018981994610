import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Permissoes } from '../../model/usuario/permissoes';

@Injectable({
  providedIn: 'root'
})
export class PermissoesService {

  private url = environment.url+'/permissoes-usuario';

  constructor(
    private http:HttpClient
  ) { }

  public consultaPermissoesSistema(): Observable<Permissoes[]>{
    return this.http.get<Permissoes[]>(`${this.url}`);
  }

  public consultaPermissoesPaginada(page: number, size: number): Observable<Permissoes[]>{
    return this.http.get<Permissoes[]>(`${this.url}/permissoes/${page}/${size}`);
  }

  public consultaPermissoesPorModulo(modulo: string): Observable<Permissoes[]>{
    return this.http.get<Permissoes[]>(`${this.url}/permissoes-modulo/${modulo}`);
  }

  public consultaPermissoesPorId(id: number): Observable<Permissoes>{
    return this.http.get<Permissoes>(`${this.url}/permissoes/${id}`);
  }

  public cadastrarPermissao(permissao: Permissoes){
    return this.http.post(`${this.url}`, permissao);
  }
 
}
